import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import FileService from '../../../services/FileService';
import Button, { ButtonType } from '../form-control/Button';
import { Input } from '../form-control/Input';
import ExternalLinkIcon from '../icon/ExternalLink';
import Loader from '../Loader';
import { Heading, HeadingSize } from '../text/Heading';
import { FileInfo } from './FileInfo';
import SingleFileInfo from './SingleFileInfo';

type FileViewerProps = {
  open: boolean;
  file: FileInfo;
  onComplete: () => void;
};

const FileViewer: FC<FileViewerProps> = (props) => {
  const { t } = useTranslation('common');
  const { open, file, onComplete } = props;
  const [downloadInProgress, setDownloadInProgress] = useState(false);
  const [openInProgress, setOpenInProgress] = useState(false);

  const downloadFile = () => {
    setDownloadInProgress(true);
    FileService.getFile(file.id).then((fileBlob) => {
      const url = URL.createObjectURL(fileBlob);

      const link = document.createElement('a');
      link.download = file.name;
      link.target = '_blank';
      link.href = url;
      link.click();

      setTimeout(() => {
        URL.revokeObjectURL(url);
      }, 100);

      setDownloadInProgress(false);
    });
  };

  const openFile = () => {
    setOpenInProgress(true);
    FileService.getFile(file.id).then((fileBlob) => {
      const url = URL.createObjectURL(fileBlob);

      const link = document.createElement('a');
      link.target = '_blank';
      link.href = url;
      link.click();

      setTimeout(() => {
        URL.revokeObjectURL(url);
      }, 100);

      setOpenInProgress(false);
    });
  };

  useEffect(() => {
    if (open) {
      document.body.classList.add('overflow-hidden');
    } else {
      document.body.classList.remove('overflow-hidden');
    }

    return () => {
      document.body.classList.remove('overflow-hidden');
    };
  }, [open]);

  if (!open) {
    return <></>;
  }

  return (
    <div
      className={`z-modal fixed bottom-0 left-0 right-0 top-0 ml-20 mt-12 transform bg-white px-8 py-8 transition-all duration-150 ease-in`}
      data-cy="file-viewer"
    >
      <div className="border-gray-5 flex h-full w-full flex-col rounded-md border-2 p-3">
        <Heading size={HeadingSize.H4} data-cy="file-name">
          {file.name}
        </Heading>
        <div className="flex w-full justify-end">
          <div className="flex">
            <Button type={ButtonType.SECONDARY} onClick={downloadFile} disabled={downloadInProgress} data-cy="download-file">
              {downloadInProgress ? <Loader size={6} /> : <>{t('file-viewer.buttons.download')}</>}
            </Button>
            &nbsp;
            <Button type={ButtonType.SECONDARY} onClick={openFile} disabled={openInProgress} data-cy="open-file">
              {openInProgress ? (
                <Loader size={6} />
              ) : (
                <>
                  {t('file-viewer.buttons.open')} <ExternalLinkIcon />
                </>
              )}
            </Button>
          </div>
        </div>
        <div className="flex h-full w-full items-center justify-center self-center">
          <div className="flex flex-col justify-center overflow-hidden py-6 sm:w-4/5 md:w-4/6 lg:w-1/3">
            <div className="pb-4">
              <SingleFileInfo file={file} />
            </div>
            <Heading size={HeadingSize.H5}>{t('file-viewer.heading')}</Heading>
            <div>{t('file-viewer.name-input.label')}</div>
            <Input value={file.name} disabled placeholder={t('file-viewer.name-input.placeholder')} data-cy="file-name-input" />
            <div>{t('file-viewer.version-input.label')}</div>
            <Input value="v1" disabled data-cy="file-version" />
          </div>
        </div>
        <div className="flex justify-center">
          <div>
            <Button onClick={onComplete} className="mx-auto" type={ButtonType.PRIMARY} data-cy="close-viewer">
              {t('file-viewer.buttons.done')}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FileViewer;
